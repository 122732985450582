<template>
    <div id="app">
        <div class="room_center_content" style="margin-top: 90px">
            <div class="right-top-file">
                <div class="hand op" style="font-size: 20px; font-weight: 600" @click="$router.go(-1)">
                    <i class="el-icon-arrow-left"></i>返回
                </div>
                <div style="width: 1280px; font-size: 20px; font-weight: 600;">
                    <div style="display: flex; justify-content: center">
                        <span>创建Room</span>
                    </div>
                </div>
            </div>
            <div style="padding:30px 60px;background:#FFF;" class="form-room-add">
                <el-form label-width="120px" size="medium" label-position="top">
                    <div style="display: flex; margin-bottom: 40px">
                        <el-form-item style="flex: 1;">
                            <div class="title-form"><span style="color:#FF0000">*</span>Room类别</div>
                            <div>
                                <el-select v-model="room.roomType" placeholder="房间类型" style="width: 300px" @change="getOrganRoomList">
                                    <el-option label="项目Room" value="1"></el-option>
                                    <el-option label="话题Room" value="2"></el-option>
                                    <el-option v-if="user.userType === 2" label="机构Room" value="3"></el-option>
                                    <el-option label="运营Room" value="4"></el-option>
                                </el-select>
                            </div>
                        </el-form-item>

                        <el-form-item style="flex: 1" v-if="room.roomType == '3'">
                            <div class="title-form">父Room
                            </div>
                            <div>
                                <el-select clearable v-model="room.fid" placeholder="选择Room" style="width: 300px">
                                    <el-option v-for="(item, index) in fatherList" :key="index" :label="item.name"
                                               :value="item.id"></el-option>
                                </el-select>
                            </div>
                        </el-form-item>

                        <el-form-item style="flex: 1" v-if="room.roomType != '3'">
                            <div class="title-form">关联机构Room
                            </div>
                            <div>
                                <el-select clearable v-model="room.relevanceRoom" placeholder="选择Room" style="width: 300px">
                                    <el-option v-for="(item, index) in organList" :key="index" :label="item.name"
                                               :value="item.id"></el-option>
                                </el-select>
                            </div>
                        </el-form-item>
                    </div>

                    <div style="margin-bottom: 40px" v-if="room.roomType == '1'">
                        <div class="title-form" style="margin-bottom: 0"><span style="color:#FF0000">*</span>项目地址</div>
                        <div style="display: flex">
                            <div style="margin: 16px 0 0 0;">
                                <address-select @change="selectCity" :address ="room.address"  :lv="3" :info="true" :multiple="false"></address-select>
                            </div>
                        </div>
                    </div>

                    <div style="margin-bottom: 40px">
                        <div class="title-form">标准发布菜单<span style="font-size: 13px; color: #999999">（可勾选不同Room类别的对应菜单，Room创建成功后附带已选择的菜单）</span>
                        </div>
                        <el-checkbox-group v-model="room.contentTypeList">
                            <div style="margin-bottom: 10px; display: flex; flex-wrap: wrap;">
                                <div v-for="(item, index) in menuList" :key="index">
                                    <div class="card" v-if="item.roomType == room.roomType">
                                        <div class="card-title">
                                            <el-checkbox :label="item">{{item.menu.name}}</el-checkbox>
	                                        <div style="color: #2970FF; font-size: 14px">
		                                        <span class="hand op" style="margin-right: 12px" @click="editMenu(item)">编辑</span>
	                                        </div>
                                        </div>

                                        <dl>
                                            <dt class="card-li">
                                                <div style="display: flex">
                                                    <span style="font-size: 14px">可发<span style="margin: 0 20px 0 10px">|</span></span>
                                                    <el-checkbox-group v-model="item.menu.writePermission">
                                                        <el-checkbox label="2">管理员</el-checkbox>
                                                        <el-checkbox label="3">成员</el-checkbox>
                                                        <el-checkbox label="5">关注者</el-checkbox>
                                                    </el-checkbox-group>
                                                </div>
                                            </dt>
                                            <dt class="card-li">
                                                <div style="display: flex">
                                                    <span style="font-size: 14px">可读<span style="margin: 0 20px 0 10px">|</span></span>
                                                    <el-checkbox-group v-model="item.menu.readPermission">
                                                        <el-checkbox label="2">管理员</el-checkbox>
                                                        <el-checkbox label="3">成员</el-checkbox>
                                                        <el-checkbox label="5">关注者</el-checkbox>
                                                    </el-checkbox-group>
                                                </div>
                                            </dt>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </el-checkbox-group>
                    </div>

                    <div style="margin-bottom: 40px">
                        <div class="title-form">
                            通用模板菜单
                            <span style="font-size: 13px; color: #999999">（模板菜单可选择系统推荐的通用模板格式，选择后自动创建使用该模板名称的菜单）</span>
                        </div>
                        <el-checkbox-group v-model="room.contentTypeList">
                            <div class="card-box">
                                <div v-for="(item, index) in hotList" :key="index" v-if="room.roomType == item.roomType">
                                    <div class="card">
                                        <div class="card-title">
                                            <el-checkbox :label="item">{{item.menu.name}}</el-checkbox>
                                        </div>
                                        <dl>
                                            <dt class="card-li">
                                                <div style="display: flex">
                                                    <span style="font-size: 14px">可发<span style="margin: 0 20px 0 10px">|</span></span>
                                                    <el-checkbox-group v-model="item.menu.writePermission">
                                                        <el-checkbox label="2">管理员</el-checkbox>
                                                        <el-checkbox label="3">成员</el-checkbox>
                                                        <el-checkbox label="5">关注者</el-checkbox>
                                                    </el-checkbox-group>
                                                </div>
                                            </dt>
                                            <dt class="card-li">
                                                <div style="display: flex">
                                                    <span style="font-size: 14px">可读<span style="margin: 0 20px 0 10px">|</span></span>
                                                    <el-checkbox-group v-model="item.menu.readPermission">
                                                        <el-checkbox label="2">管理员</el-checkbox>
                                                        <el-checkbox label="3">成员</el-checkbox>
                                                        <el-checkbox label="5">关注者</el-checkbox>
                                                    </el-checkbox-group>
                                                </div>
                                            </dt>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </el-checkbox-group>
                    </div>

                    <div style="margin-bottom: 40px">
                        <div class="title-form"><span style="color:#FF0000">*</span>Room名称</div>
                        <div>
                            <el-input v-model="room.name" :show-word-limit="true" @blur="checkoutRoom" maxlength="30" minlength="4"
                                      placeholder="Room名称"></el-input>
                        </div>
                    </div>

                    <div style="margin-bottom: 40px">
                        <div class="title-form"><span style="color:#FF0000">*</span>Room介绍</div>
                        <div>
                            <el-input v-model="room.detail" type="textarea" :rows="5"
                                      :autosize="{ minRows: 5, maxRows: 15 }" :show-word-limit="true" maxlength="500"
                                      minlength="5" placeholder="Room介绍"></el-input>
                        </div>
                    </div>

                    <div style="margin-bottom: 40px">
                        <div class="title-form"><span style="color:#FF0000">*</span>Room标签</div>
                        <div style="display: flex; margin-bottom: 4px">
                            <el-input v-model="tag" :show-word-limit="true" maxlength="20" minlength="2"
                                      placeholder="标签名称" style="margin-right: 20px"></el-input>
                            <span @click="add_tag"
                                  style="width: 100px; height: 20px; font-size: 12px; background-color: #F5F7FA; border: 1px #CED0D4 solid; text-align: center; border-radius: 5px; padding: 10px 0"><i
                                    class="el-icon-plus" style="margin-right: 4px"></i>添加标签</span>
                        </div>
                        <el-tag closable @close="room.tags.splice(index,1)" v-for="(item,index) in room.tags"
                                :key="index"
                                size="small" style="margin:0 5px;">{{ item }}
                        </el-tag>
                    </div>

                    <div style="margin-bottom: 40px">
                        <div class="title-form"><span style="color:#FF0000">*</span>Room关联行业</div>
                        <div class="inner-container" style="height: 1000px; width: 1000px; border: 1px #CED0D4 solid">
                            <div class="sub_types">
                                <div v-for="(type,index) in typeList" :key="index">
                                    <div class="type_name"
                                         style="font-weight: 600; padding:10px 20px; background-color: #F5F7FA; font-size: 16px; display: flex; justify-content: space-between; border-bottom: 1px #CED0D4 solid">
                                        <div>{{ type.label }}</div>
                                        <div style="font-size: 13px; font-weight: 400">当前行业有<span style="color:#FF0000">{{type.num}}个</span>选项
                                        </div>
                                    </div>
                                    <div style="margin: 12px 20px" v-for="(sub,subindex) in type.children"
                                         :key="subindex">
                                        <div style="color: #2970FF; font-weight: 600">{{sub.label}}</div>
                                        <div style="overflow:auto;">
                                            <div v-for="(tag,tagIndex) in sub.children" :key="tagIndex">
                                                <div class="sub_type hand op sc" :class="{'active':tag.check}"
                                                     @click="set_type_id(tag.value,tag.label,index,subindex,tagIndex)">
                                                    {{ tag.label }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="margin-bottom: 40px">
                        <div class="title-form">Room内部成员加入方式</div>
                        <div>
                            <el-radio v-model='room.auth' label="0" style="margin-bottom: 30px; margin-top: 10px">
                                允许在room广场进行推荐，关注者可以申请成为room内部成员
                            </el-radio>
                            <el-radio v-model='room.auth' label="1">只能通过房主邀请的方式加入该room（不会在room广场进行推荐并无法被搜索到）
                            </el-radio>
                        </div>
                    </div>

                    <div style="margin-bottom: 40px" v-if="room.auth == 0">
                        <div class="title-form">Room发布权限</div>
                        <el-checkbox v-model="room.fansInput" true-label="0" false-label="1">允许关注者在room中发布标准内容</el-checkbox>

                    </div>
	                <div style="margin-bottom: 40px" v-if="room.auth == 0">
		                <div class="title-form">Room口令</div>
		                <el-input v-model="room.password" :show-word-limit="true" maxlength="12" minlength="6"
		                          placeholder="设置口令（6-12位的字母和数字的组合）" style="width: 400px" size="small"/>
		                <!--		                <span style="color: #c1c1c1;margin-left: 20px;"> 通过房主邀请的方式加入该room时不可使用</span>-->
	                </div>
	                <div style="margin-bottom: 40px">
		                <div class="title-form">Room专家团队</div>
		                <el-checkbox v-model="room.expertTeam" :true-label="1" :false-label="0">允许专家团队在专家连线中显示</el-checkbox>
	                </div>

                    <div style="margin-bottom: 40px; display: flex">
                        <div style="flex: 1">
                            <div class="title-form">Room内部成员邀请</div>
                            <div>
                                <el-select v-model="room.userList" placeholder="内部成员邀请" multiple collapse-tags>
                                    <el-option
                                            v-for="(item, index) in friendsList"
                                            :key="index"
                                            :label="item.friendInfo.nickName"
                                            :value="item.friendInfo.uuid">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>

                        <div style="flex: 1" v-if="user.userType === 2">
                            <div class="title-form">机构账号子账号邀请</div>
                            <div>
                                <el-select v-model="room.subList" placeholder="子账号邀请" multiple v-el-select-loadmore="loadMore" @visible-change="showOptions">
                                    <el-option
                                            v-for="(item, index) in subList"
                                            :key="index"
                                            :label="item.nickName"
                                            :value="item.uuid">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>

                    <div style="display: flex; margin-bottom: 40px">
                        <el-form-item style="flex: 1">
                            <div class="title-form"><span style="color:#FF0000">*</span>Room背景图片</div>
                            <div>
                                <el-upload class="upload-demo" drag multiple
                                           action="https://jsonplaceholder.typicode.com/posts/"
                                           :show-file-list="false" :http-request="upload_back"
                                           :before-upload="beforeBackUpload">
                                    <img v-if="room.cover != '' " :src="room.cover" width="100%" height="338px">
                                    <i class="el-icon-plus icon-plus"></i>
                                    <div style="font-size: 20px">将图片拖到此处，或<em>点击上传</em></div>
                                </el-upload>
                            </div>
                        </el-form-item>
                    </div>
                    <div style="display: flex; justify-content: end">
                        <el-button type="primary" @click="add" style="">创建Room</el-button>
                    </div>

                </el-form>
            </div>
        </div>
	    <el-dialog :title="menuId === '' ? '添加菜单' : '编辑菜单'" :visible.sync="showMenuAdd" width="50%">
		    <el-form label-width="120px" size="medium" label-position="left">
			    <el-form-item label="菜单内容格式" >
				    <el-select v-model="roomFrom.menuType" placeholder="菜单内容格式" v-if="roomFrom.isKw !== 1">
					    <el-option v-for="(item, index) in optionTemplate" :key="index" :label="item.title" :value="item.id"></el-option>
				    </el-select>
            <el-select v-model="roomFrom.menuType" :disabled="true" placeholder="菜单内容格式" v-else>
              <el-option  label="知识仓库" :value="0"></el-option>
            </el-select>
			    </el-form-item>

			    <el-form-item label="菜单名称">
				    <el-input v-model="roomFrom.name" :show-word-limit="true" maxlength="10" minlength="2" placeholder="菜单名称"></el-input>
			    </el-form-item>

			    <el-form-item label="菜单排序">
				    <el-input-number v-model="roomFrom.sort" :max="20" :min="1" placeholder="菜单排序"></el-input-number>
			    </el-form-item>


			    <el-form-item label="可发布">
				    <el-checkbox-group v-model="roomFrom.writePermission">
					    <el-checkbox label="2">管理员</el-checkbox>
					    <el-checkbox label="3">成员</el-checkbox>
					    <el-checkbox label="5">关注者</el-checkbox>
				    </el-checkbox-group>
			    </el-form-item>

			    <el-form-item label="可阅读">
				    <el-checkbox-group v-model="roomFrom.readPermission">
					    <el-checkbox label="2">管理员</el-checkbox>
					    <el-checkbox label="3">成员</el-checkbox>
					    <el-checkbox label="5">关注者</el-checkbox>
				    </el-checkbox-group>
			    </el-form-item>
		    </el-form>

		    <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="changeMenu" size="small">完 成</el-button>
            </span>
	    </el-dialog>
    </div>
</template>

<script>
    import Head from "../../components/common/Head";
    import AddressSelect from "../../components/common/city/AddressSelect";
    import router from "@/router";

    export default {
        name: "add",
        components: {
            AddressSelect,
            Head
        },
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login() {
                return this.$store.state.is_login
            }
        },
        data() {
            return {
                room: {
                    fid: '',
                    relevanceRoom: '',
                    cover: "",
                    name: "",
                    detail: "",
                    contentTypeList: [],
                    tags: [],
                    industry: [],
                    userList: [],
                    subList: [],
                    roomType: "1",
                    auth: '0',
                    fansInput: '0',
                    password: '',
                    address: {},
	                expertTeam:0,
                },
	            roomFrom: {
		            name: '',
		            menuType: '',
		            readPermission: [],
		            writePermission: [],
		            sort: '',
		            isFidShow: 0,
	            },
                typeList: [],

                tag: '',
                group: [],
                loading: false,

                menuList: [],

                hotList: [],

                fPage: 1,
                fPageSize: 10,
                like: '',
                id: '',
                fatherList: [],

                organList: [],

                page: 1,
                pageSize: 10,
                friendsList: [],

                hotDiyTemplates: [],

                subPage: 1,
                subMore: 1,
                subList: [],
	            showMenuAdd:false,
	            menuId: '',
	            optionTemplate: [],
	            roomInfo: {},

            }
        },
        props: {},
        directives: {
            // 下拉框懒加载
            'el-select-loadmore': {
                bind(el, binding) {
                    const SELECTWRAP_DOM = el.querySelector(
                        '.el-select-dropdown .el-select-dropdown__wrap'
                    );
                    SELECTWRAP_DOM.addEventListener('scroll', function() {
                        const condition =
                            this.scrollHeight - this.scrollTop <= this.clientHeight;
                        if (condition) {
                            binding.value();
                        }
                    });
                }
            }
        },
        created: function () {
            this.getTypeList();
	        this.$EventBus.$emit("is_show", {is_show: 1});
	        this.getHotDiy(1)
        },
        mounted: function () {
            this.backTop();
            this.getOrganRoomList();
            // this.getFatherRoomList();
            this.getFriendsList();
            this.getRoomMenu();

        },

        methods: {
          router() {
            return router
          },
          // 检查room名称
          checkoutRoom() {
            console.log(this.room.name)
            this.newApi.checkRoom({name: this.room.name}).then(res => {
              if (res.data === 1) {
                this.utils.msg('room名称重复，请重新输入')
                this.room.name = ''
              }
            })
          },


	        // 编辑
	        editMenu(item) {
		        this.menuId = item.id;
		        this.showMenuAdd = true;
		        this.roomFrom = item.menu;

	        },
	        // 添加
	        changeMenu() {
		        if (this.roomFrom.name.length < 2) {
			        this.utils.err('菜单名称过短！');
			        return
		        }
		        this.showMenuAdd = false;
	        },
            // 地址设置
            selectCity(address) {
                this.room.address = address
            },

            backTop() {
                document.documentElement.scrollTop = document.body.scrollTop = 0;
            },

            // 获取Room默认菜单
            getRoomMenu() {
                this.newApi.getRoomDefaultMenu().then(res => {
                    this.menuList = res.data.default;
                    this.hotList = res.data.template;
                })
            },
	        // 获取通用模板文档
	        getHotDiy(e) {
		        if (e === 3) {
			        this.newApi.getRoomTemplateList({roomId:0, page: this.page, pageSize: this.pageSize, type: JSON.stringify([e])}).then(res => {
				        for (const item of res.data.diy) {
					        item.show = false
				        }
				        this.optionTemplate = res.data.diy
			        })
		        }
		        if (e === 1) {
			        this.newApi.getRoomTemplateList({roomId: 0, page: this.page, pageSize: this.pageSize, type: JSON.stringify([e])}).then(res => {
				        this.optionTemplate = res.data.default
			        })
		        }
	        },

            // 获取Room模板文档


            // 获取下拉选项
            showOptions(e) {
                if (e === true) {
                    this.getOrganList()
                } else {
                    this.subList = []
                }
            },

            // 下拉框懒加载
            loadMore() {
                if(this.subMore === 1) {
                    this.getOrganList();	//类似于分页查询
                }
            },

            // 获取机构子账号
            getOrganList() {
                this.newApi.getInstitutionContacts({page: this.subPage, pageSize: this.pageSize}).then(res =>{
                    for (const item of res.data) {
                        this.subList.push(item);
                        this.subMore = 1;
                    }
                    if (res.data.length < 10) {
                        this.subMore = 0;
                    } else {
                        this.subPage ++;
                    }

                })
            },

            // 获取机构Room
            getOrganRoomList() {
                this.newApi.getMyDutyRoomList({type: 7, roomType: 3}).then(res => {
                    this.fatherList = res.data;
                    this.organList = res.data
                })
            },

            remoteMethod(e) {
                this.like = e;
            },

            // 获取好友列表
            getFriendsList() {
                this.newApi.getFriendList().then(res => {
                    this.friendsList = res.data
                })
            },

            add_tag() {
                if (this.tag) {
                    this.room.tags.push(this.tag);
                    this.tag = ''
                }
            },

            showRoomType(roomType) {
                if (roomType == "1") {
                    return "项目room";
                } else if (roomType == "2") {
                    return "兴趣room";
                } else if (roomType == "3") {
                    return "机构room";
                } else if (roomType == "4") {
                    return "经营room";
                }
            },

            add: function () {
                if (!this.utils.toAuth(1)) {
                  return false;
                }
                var that = this;
                if (this.room.roomType == "") {
                    this.utils.err("请输入Room类别");
                    return;
                }
                if (this.room.roomType == "1" && JSON.stringify(this.room.address) == "{}") {
                    this.utils.err("项目Room须填写项目地址");
                    return;
                }
                if (this.room.name == "") {
                    this.utils.err("请输入Room名称");
                    return;
                }
                if (this.room.detail == "") {
                    this.utils.err("请输入Room介绍");
                    return;
                }
                if (this.room.tags.length == 0) {
                    this.utils.err("请添加至少一个标签");
                    return;
                }
                if (this.room.industry.length == 0) {
                    this.utils.err("请选择Room关联行业");
                    return;
                }
                if (this.room.cover == "") {
                    this.utils.err("上传Room背景图片");
                    return;
                }

                if (this.room.auth == 0 ) {
                  this.room.password = ''
                }

                if (this.room.password !== '' &&  !/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/.test(that.room.password)) {
                    this.utils.err("口令格式不正确!");
                    return;
                }

                var params = {};
                params.userList = [];
                params.name = this.room.name;
                params.detail = this.room.detail;
                params.cover = this.room.cover;
                params.roomType = this.room.roomType;
                params.address = JSON.stringify(this.room.address);
                if (this.room.fid) {
                    params.fid = this.room.fid;
                } else {
                    params.fid = 0
                }
                if (this.room.relevanceRoom) {
                    params.relevanceRoom = this.room.relevanceRoom;
                } else {
                    params.relevanceRoom = 0
                }
                params.auth = this.room.auth;
                params.fansInput = this.room.fansInput;
                if (this.room.password) {
                    params.password = this.room.password;
                }
                params.tags = JSON.stringify(this.room.tags);
                params.industry = JSON.stringify(this.room.industry);
                params.menuList= JSON.stringify(this.room.contentTypeList);
                params.userList = JSON.stringify(this.room.userList);
                params.subList = JSON.stringify(this.room.subList);
	            params.expertTeam = this.room.expertTeam;
                this.newApi.addRoom(params).then(res => {
                    if (res.isSuccess === 1) {
                        that.utils.sus(res.data);
                        that.utils.a('/room/index/')
                    }

                })
            },

            set_type_id: function (id, name, index, subIndex, tagIndex) {
                var type_options = this.typeList;
                if (type_options[index].children[subIndex].children[tagIndex].check == null) {
                    type_options[index].children[subIndex].children[tagIndex].check = true;
                    type_options[index].num += 1
                } else {
                    type_options[index].children[subIndex].children[tagIndex].check = !type_options[index].children[subIndex].children[tagIndex].check;
                    if (type_options[index].children[subIndex].children[tagIndex].check === true) {
                        type_options[index].num += 1
                    } else {
                        type_options[index].num -= 1
                    }
                }
                this.typeList = type_options;
                this.$set(this.typeList, index, type_options[index]);
                this.check_types_ok()
            },

            check_types_ok: function () {
                var typeOptions = this.typeList;
                var industry = [];
                for (const i of typeOptions) {
                    for (const k of i.children) {
                        for (const j of k.children) {
                            if (j.check) {
                                var obj = {
                                    id: j.value,
                                    name: j.label
                                };
                                industry.push(obj);
                            }
                        }
                    }
                }
                this.room.industry = industry;
            },

            getTypeList: function () {
                var that = this;
                this.newApi.getTypeList({
                    lv: 3
                }).then(res => {
                    for (const item of res.data) {
                        item.num = 0
                    }
                    that.typeList = res.data;
                })
            },

            // 上传背景图片及头像
            upload_back: function (params) {
                var file = params.file;
                var that = this;
                that.utils.upload(file, function (url) {
	                if (!url){
		                return false;
	                }
                    that.room.cover = url.url;
                })
            },

            beforeBackUpload(file) {
                let isImage = ['image/jpeg', 'image/png'];
                const isLt2M = file.size / 1024 / 1024 < 20;
                if (isImage.indexOf(file.type) === -1) {
                    this.$message.error('请上传正确的图片格式');
                    return false;
                }
                if (!isLt2M) {
                    this.$message.error('图片大小不能超过 20MB');
                    return false;
                }
                return true;
            },
        }
    }

</script>
<style>
    .right-top-file {
        margin: 10px 0 2px 0;
        background-color: #fff;
        display: flex;
        padding: 12px 20px;
        box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.1600);
    }

    .room_center_content {
        width: 1500px;
        margin: 0 auto;
        padding: 2px 0 0 0;
    }

    .form-room-add .title-form {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #2970FF;
        font-weight: 600;
        width: 80px;
        height: 80px;
        background-color: #FBFBFB;
        line-height: 80px;
        text-align: center;
    }

    .avatar {
        width: 80px;
        height: 80px;
        display: block;
    }

    .el-drawer {
        background-color: rgba(0, 0, 0, 0.3);
    }
</style>
<style scoped>

    .card-box {
        display: flex;
        flex-wrap: wrap;
    }

    .card {
        width: 312px;
        margin-right: 10px;
        margin-bottom: 20px;
        border: 1px #CED0D4 solid;
    }

    .card-title {
        height: 50px;
        padding: 0 20px;
        line-height: 50px;
        display: flex;
        justify-content: space-between;
        background-color: #F8F8F8;
        border-bottom: 1px #CED0D4 solid;
    }

    .card-li {
        height: 50px;
        line-height: 50px;
        padding: 0 10px;
        border-bottom: 1px #CED0D4 solid;
    }

    .el-checkbox {
        margin-right: 10px;
    }
    .home-balance-head-title {
        font-size: 18px;
        color: #333;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        font-weight: 700;
        margin: 0 10px 20px 10px;
    }

    .types {
        padding: 10px;
        background: #FFF;
        overflow: auto;
    }

    .types .type {
        float: left;
        width: 16.6%;
        text-align: center;
        font-size: 14px;
    }

    .sub_types .sub_type {
        float: left;
        margin: 10px 16px 0 0;
        padding: 4px 10px;
        border-radius: 5px;
        border: 1px solid #BCD2FF;
    }

    .sub_type.active {
        color: #FFFFFF;
        background: #FFA500;
        border: 1px solid #FFA500;
        margin: 10px 16px 0 0;
    }

    .room_center_content .upload-demo {
        vertical-align: middle;
    }

    /deep/ .room_center_content .upload-demo .el-upload-dragger {
        width: 486px;
        height: 318px;
        background-color: #FBFBFB;
    }

    .room_center_content .icon-plus {
        color: #2970FF;
        font-size: 44px;
        font-weight: 600;
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        margin: 100px 0 14px 0;
    }

    .room_center_content .inner-container {
        overflow-x: hidden;
        overflow-y: scroll;
    }

    /deep/ .el-checkbox-group {
        font-size: 16px;
    }

</style>
